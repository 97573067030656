<template>
  <div>
    <h4 class="text-center mt-5">Logging in...</h4>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    token() {
      return this.$route.params.token;
    }
  },
  mounted() {
    this.autoLogin();
  },
  methods: {
    autoLogin() {
      this.$store.dispatch("auth/autoLogin", { token: this.token }).then(() => {
        window.location.href = "/";
      });
    }
  }
};
</script>
